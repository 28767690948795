import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import green from '@material-ui/core/colors/green';
import firebase from './firebase.js';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '80px 20px'
  },
  title: {
    width: '100%',
  },
  heading: {
    width: '100%',
    marginBottom: 0
  },
  textField: {
    marginLeft: 0,
    marginRight: 0,
    width: '100%',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  wrapper: {
    maxWidth: 400,
    margin: '0 auto'
  },
  button: {

  },
});
const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

class App extends React.Component {
  state = {
    name: '',
    text: '',
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    msgSuccess: 'Tack för din ansökan!',
    msgError: 'Hoppsan, något gick fel. Försök igen!',
    msg: '',
    variant: 'success',
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };


  handleSubmit = event => {
    event.preventDefault();

    if(this.state.name.length > 1 && this.state.text.length > 1){
      const applicationRef = firebase.database().ref('applications');
      const application = {
        name: this.state.name,
        text: this.state.text
      };

      applicationRef.push(application);

      this.setState({
        name: '',
        text: '',
        msg: this.state.msgSuccess,
        variant: 'success',
        open: true,
      });
    }else{
      this.setState({
        msg: this.state.msgError,
        variant: 'error',
        open: true,
      });
    }
  };


  handleClose = () => {
    this.setState({ open: false });
  };


  render() {
    const { classes } = this.props;
    const { vertical, horizontal, open } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.wrapper}>
          <form className={classes.container} noValidate autoComplete="on">
            <Typography className={classes.heading} component="h6" variant="h6" gutterBottom>
              Ansökan
            </Typography>
            <Typography className={classes.title} component="h4" variant="h4" gutterBottom>
              Hacka Livet
            </Typography>
            <TextField
              id="standard-name"
              label="Namn"
              className={classes.textField}
              value={this.state.name}
              onChange={this.handleChange('name')}
              margin="normal"
              variant="outlined"
            />


            <TextField
              id="standard-multiline-flexible"
              label="Ansökan"
              multiline
              rowsMax="10"
              rows="5"
              value={this.state.text}
              onChange={this.handleChange('text')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            <Button variant="contained" color="primary" className={classes.button} onClick={this.handleSubmit}>
              Skicka
            </Button>
          </form>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={this.handleClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              variant={this.state.variant}
              message={this.state.msg}
            />
          </Snackbar>
        </div>
      </React.Fragment>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
