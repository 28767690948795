import firebase from 'firebase'
const config = {
  apiKey: "AIzaSyCKvns_WmPCXm8jW4qgzVV7kRDidCbwUFs",
  authDomain: "hackalivet-3cf77.firebaseapp.com",
  databaseURL: "https://hackalivet-3cf77.firebaseio.com",
  projectId: "hackalivet-3cf77",
  storageBucket: "hackalivet-3cf77.appspot.com",
  messagingSenderId: "340526522647"
};
firebase.initializeApp(config);
export default firebase;